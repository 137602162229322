"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
window.bahlsen = window.bahlsen || {};

/**
 * Main application class.
 * @class App
 * @static
 */
bahlsen.App = function App() {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  var config = {
    env: 'production',
    csrf: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {}
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Store application settings
    options = options || {};
    if (options.env) {
      config.env = options.env;
    }
    if (options.csrf) {
      config.csrf = options.csrf;
    }
    if (options.locale) {
      config.locale = options.locale;
    }
    if (options.device) {
      config.device = options.device;
    }
    if (options.preview) {
      config.preview = options.preview;
    }
    if (options.general) {
      config.general = options.general;
    }
    if (_typeof(bahlsen.Search) === 'object') bahlsen.Search.init();
    if (_typeof(bahlsen.Mobile) === 'object') bahlsen.Mobile.init();
    if (_typeof(bahlsen.Map) === 'object') bahlsen.Map.init();
    if (_typeof(bahlsen.Modal) === 'object') bahlsen.Modal.init();
    if (_typeof(bahlsen.categoriesMenu) === 'object') bahlsen.categoriesMenu.init();
    if (_typeof(bahlsen.AjaxMailchimp) === 'object') bahlsen.AjaxMailchimp.init();
    // Initialize child classes
    // if (typeof site.HelperReminder === 'object') {
    //   site.HelperReminder.init();
    // }

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    config: getConfig
  };
}();