"use strict";

window.bahlsen = window.bahlsen || {};

/**
 * Utilities for jQuery to Javascript Helper/Reminder
 * @class HelperReminder
 * @static
 */
bahlsen.AjaxMailchimp = function AjaxMailchimp() {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false,
    $mailchimpForm = $('[data-ajax="mailchimp-subscribe"]');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    //Initiate AjaxMailchimp
    $mailchimpForm.on("submit", function (event) {
      event.preventDefault();
      var $form = $(this);
      $.ajax({
        type: 'post',
        url: $('[name="action"]', $form).val(),
        data: $form.serializeArray(),
        'contentType': 'application/x-www-form-urlencoded; charset=UTF-8',
        'cache': false,
        success: function success(response) {
          if (response.success) {
            $('.js-news-message').slideDown();
            setTimeout(function () {
              $('.js-news-message').slideUp();
              $form[0].reset();
            }, 5000);
          }
        }
      });
    });
    return true;
  };
  return {
    init: init
  };
}();