"use strict";

window.bahlsen = window.bahlsen || {};

/**
 * Utilities for jQuery to Javascript Helper/Reminder
 * @class HelperReminder
 * @static
 */
bahlsen.categoriesMenu = function categoriesMenu() {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    menu();
    return true;
  };
  var menu = function menu() {
    var categories = true;
    var _hideCategory = document.querySelector('.show-hide-categories'),
      _subMenu = document.querySelector('.sub-menu-link-wrap');
    if (_hideCategory) {
      _hideCategory.addEventListener('click', function (e) {
        e.preventDefault();
        if (categories == true) {
          _hideCategory.classList.add('open');
          _subMenu.style.display = 'block';
          categories = false;
        } else if (categories == false) {
          _hideCategory.classList.remove('open');
          _subMenu.style.display = 'none';
          categories = true;
        }
      });
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();